.payment-info{
    text-align: center;
}
.payment-info .popup-image{
    margin-top: 50px;
}
.payment-info p{
    margin-bottom: 30px;
}
.payment-info h5{
    text-align: center;
    margin-bottom: 10px;
    color: #393337;
}
.payment-info .buttons{
    display: flex;
    justify-content: center;
}
.payment-info .primary{
    background: #393337;
    border-radius: 4px;
    padding: 2px;
    width: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--gray-color);
}