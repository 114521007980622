.single-project-full{
    background: #1E1E1E;
    padding-bottom: 110px;
}
.single-project-top{
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.single-project-full .single-project-top .iframe-container{
    height: 100vh;
    margin-bottom: 0;
}
.single-project-top img, .single-project-top video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.single-project-top h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    text-align: center;
}
.single-project-full .container{
    padding: 0 10px;
}
.single-project-text-content{
    color: var(--gray-color);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 30px;
    padding-top: 30px;
}

.single-project-text-content .left-side{
    width: 35%;
}
.single-project-text-content .right-side{
    width: 60%;
    margin-left: auto;
}
.single-project-text-content .right-side p{
    margin-bottom: 23px;
}
.single-project-text-content .right-side p:last-child{
    margin-bottom: 0;
}
.single-project-text-content + .listen{
    margin-top: 0;
}
.iframe-container + .single-project-text-content{
    margin-top: 30px;
}
.single-project-text-content.large .left-side{
    width: 60%;
}
.single-project-text-content.large .right-side{
    width: 35%;
}
.single-project-full-photo{
    margin-left: auto;
    width: 60%;
    padding-bottom: 30px;
}
.single-project-full-photo img{
    width: 100%;
    margin-bottom: 10px;
}
.single-project-full-photo p{
    color: #393337;
}
.listen{
    background: #393337;
    border: 1px solid #FF4200;
    border-radius: 18px;
    padding: 50px 30px;
    margin-bottom: 30px;
    margin-top: 10px;
    transition: all 0.4s ease;
}
.listen:hover{
    border: 1px solid #898E99;
    background-color: transparent;
}
.listen .h2{
    text-align: left;
    transition: all 0.4s ease;
    color: #000000;
}
.listen:hover .h2{
    color: #ff4200;
}
@media (prefers-color-scheme: dark) {
    .listen .h2{
        color: #000000;
    }
}
.single-project-full .iframe-container{
    background: transparent;
    margin-bottom: 20px;
    height: auto;
}
.single-project-full .iframe-container.small-height{
    padding: 35.25% 0 0 0;
}
.single-project-full .iframe-container.right{
    width: 60%;
    margin-left: auto;
}
.single-project-full .iframe-container.left{
    width: 60%;
}
.single-project-full .iframe-container.full{
    width: 100%;
}
.single-project-full .no-margin{
    margin-bottom: 20px;
}
.single-project-full .no-margin .iframe{
    left: -10px;
    width: calc(100% + 20px);
}
.single-project-image-block img{
    width: calc(100% + 20px);
    padding-bottom: 20px;
    margin: 0 -10px;
}
.single-project-image-block img.left{
    width: 60%;
    margin: 0;
}
.single-project-image-block img.right{
    width: 60%;
    margin: 0 0 0 auto;
}
.single-project-image-block div {
    margin: 0 -10px;
    display: flex;
}
.single-project-image-block div img{
    width: 50% !important;
    margin: 0;
}
.single-project-full .iframe-container + .single-project-text-content, .single-project-full .listen + .single-project-text-content{
    padding-top: 0;
}
.single-project-full .single-project-text-content:last-of-type{
    padding-bottom: 0;
}
.single-project-full .single-project-image-block + .single-project-text-content{
    padding-top: 10px;
}
.single-project-text-content .left-side .p{
    margin-bottom: 23px;
}
.single-project-text-content .left-side .p:last-child{
    margin-bottom: 0;
}
.single-project-text-content .left-side.empty{
    margin-bottom: 0;
}
.video-block{
    width: 100%;
}
.video-block.left{
    width: 60%;
}
.video-block.right{
    width: 60%;
    margin-left: 40%;
}
@media (max-width: 991px){
    .single-project-top h2{
        width: 80%;
    }
    .single-project-text-content{
        flex-direction: column;
    }
    .single-project-text-content .left-side{
        margin-bottom: 30px;
    }
    .single-project-text-content .left-side, .single-project-text-content .right-side{
        width: 80%;
        margin-left: auto;
    }
    .single-project-image-block img.left{
        width: 80%;
    }
    .single-project-image-block img.right{
        width: 80%;
        margin-left: 0;
        margin-right: auto;
    }
    .listen{
        padding: 30px;
    }
    .single-project-full .iframe-container.right{
        width: 100%;
        margin-left: auto;
    }
    .single-project-full .iframe-container.left{
        width: 100%;
    }
}
@media (max-width: 767px){
    .single-project-text-content .left-side .h5:first-of-type{
        margin-bottom: 0;
    }
    .single-project-text-content .left-side, .single-project-text-content.large .left-side{
        margin-bottom: 20px;
        width: 100%;
    }
    .single-project-text-content .right-side{
        width: 100%;
    }
    .single-project-text-content .right-side p{
        margin-bottom: 20px;
    }
    .single-project-text-content{
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .listen{
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
    }
    .single-project-image-block img, .single-project-image-block img.left, .single-project-image-block img.right{
        width: calc(100% + 20px);
        margin-left: -10px;
        padding-bottom: 20px;
    }
    .single-project-image-block div{
        flex-direction: column;
    }
    .single-project-image-block div img{
        width: 100% !important;
        margin-left: 0;
    }
    .single-project-full .single-project-top .iframe-container{
        margin-bottom: 0;
    }
    .single-project-text-content .left-side .p{
        margin-bottom: 18px;
    }
    .single-project-full .iframe-container{
        margin-bottom: 20px;
    }
    .single-project-full .no-margin{
        margin-bottom: 10px;
    }
    .single-project-text-content.mt-120{
        margin-top: 40px;
    }
    .single-project-full{
        padding-bottom: 90px;
    }
    .single-project-full .iframe-container.small-height{
        margin-left: -10px;
        margin-right: -10px;
        padding: 36.25% 0 0 0;
        margin-bottom: 20px;
    }
    .iframe-container + .single-project-text-content{
        margin-top: 0;
    }
    .single-project-full .single-project-image-block + .single-project-text-content{
        padding-top: 0;
    }
}