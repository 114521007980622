.single-artist{
    width: calc(100%/3 - 20px/3);
    margin-right: 10px;
    margin-bottom: 30px;
}
.single-artist:nth-child(3n){
    margin-right: 0;
}
.single-artist-image:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.single-artist-image{
    position: relative;
    margin-bottom: 10px;
}
.single-artist-image img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.single-artist-image button{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.single-artist-image:hover button{
    opacity: 1;
    visibility: visible;
}
.single-artist .btn-buy{
    margin-top: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: var(--gray-color);
    padding: 1px 6px;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid var(--gray-color);
    border-radius: 4px;
    transition: all 0.4s ease;
}
.btn-listen{
    border: 1px solid var(--orange-color);
    background: #1e1e1e;
    font-weight: bold;
    color: var(--orange-color);
    padding: 3px 5px;
    text-transform: uppercase;
    transition: all 0.4s ease;
}
.single-artist h5{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--gray-color);
}
.single-artist p{
    font-size: 16px;
    line-height: 18px;
    color: var(--gray-color);
}
.single-artist:nth-child(3n - 2) h5, .single-artist:nth-child(3n - 2) p, .single-artist:nth-child(3n - 2) .btn-buy{
    margin-left: 10px;
}
.single-artist .btn-buy:hover{
    background: #898E99;
    color: #1E1E1E;
}
@media (max-width: 991px){
    .single-artist{
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 40px;
    }
    .single-artist:nth-child(3n){
        margin-right: 10px;
    }
    .single-artist:nth-child(2n){
        margin-right: 0;
    }
}
@media (max-width: 767px) {
    .single-artist {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
    }
    .btn-listen{
        padding: 2px 5px;
    }
    .single-artist:nth-child(3n - 2) h5, .single-artist:nth-child(3n - 2) p, .single-artist:nth-child(3n - 2) .btn-buy{
        margin-left: 0;
    }
    .single-artist:nth-child(3n){
        margin-right: 0;
    }
    .single-artist-image{
        margin-left: -10px;
        margin-right: -10px;
    }
}