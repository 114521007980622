.architecture{
    background: #010102;
    padding-bottom: 110px;
}
.architecture-top{
    padding-top: 50px;
    position: relative;
}
.architecture-top img{
    width: 100%;
    max-height: 750px;
    object-fit: cover;
}
.architecture-top .h2{
    position: absolute;
    top: 100px;
    left: 10px;
}
.architecture-top .p{
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: var(--gray-color);
}
.architecture-text{
    width: 840px;
    margin: 60px 20px 120px auto;
}
.architecture-text p{
    font-size: 20px;
    line-height: 24px;
    color: var(--gray-color);
    margin-bottom: 24px;
}
.architecture-text .h5{
    margin-bottom: 20px;
    margin-top: 40px;
    color: var(--gray-color);
}

@media (max-width: 991px){
    .architecture-text{
        width: calc(100% - 40px);
        margin: 40px 20px;
    }
    .architecture-text p{
        font-size: 16px;
        line-height: 18px;
    }
    .architecture-top{
        padding-top: 42px;
    }
    .architecture{
        padding-bottom: 60px;
    }
}