.artists-section{
    background: #1E1E1E;
    padding-bottom: 80px;
}
.artists-section +.footer{
    background: #1E1E1E;
}
.artists{
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
}
@media (max-width: 767px){
    .artists-section{
        padding-bottom: 70px;
    }
    .artists{
        padding-left: 10px;
        padding-right: 10px;
    }
}