:root {
    --window-inner-height: 100%;
}
.catalog{
    background: #1E1E1E;
    padding-bottom: 110px;
    min-height: 100vh;
}
.catalog +.footer{
    background: #1E1E1E;
}
.tracks{
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
}

.popup{
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;
}
.popup.opened{
    opacity: 1;
    visibility: visible;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(75px);
    z-index: 11;
}
.popup-content{
    background: var(--gray-color);
    border-radius: 20px;
    width: 600px;
    padding: 0 10px 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: calc(100vh - 60px);
}
.popup-content-scroll{
    max-height: calc(100vh - 62px);
    min-height: 520px;
    overflow-y: auto;
    padding: 1px 5px;
    margin-top: 1px;
    margin-bottom: 1px;
}
.popup-content-scroll >*:last-child{
    margin-bottom: 20px;
}
.popup-content-scroll::-webkit-scrollbar-thumb{
    background-color: #D3D3D3;
    border-radius: 8px;
}
.popup-content-scroll::-webkit-scrollbar{
    width: 6px;
    scroll-padding-top: 10px;
}
.popup-close{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.03em;
    color: #D3D3D3;
    position: absolute;
    z-index: 1;
    top: 25px;
    right: 20px;
}
.popup-top-info{
    margin-bottom: 20px;
    margin-top: 25px;
    color: #393337;
}
.popup-top-info .h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}
.popup-top-info .p{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}
.popup-content .popup-image{
    width: 220px;
    margin: 0 auto 20px;
}
.popup-content .description{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #393337;
    white-space: pre-line;
}
.popup-content .links{
    display: flex;
    margin-bottom: 25px;
    margin-top: 25px;
}
.popup-content .links > *{
    margin-right: 20px;
    border: 1px solid #393337;
    border-radius: 4px;
    padding: 2px 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #393337;
}
.popup-content .links > *:last-child{
    margin-right: 0;
}
.popup-content .line{
    width: 100%;
    height: 1px;
    background-color: #393337;
    margin-bottom: 25px;
    margin-top: 25px;
}
.popup-content .list .h6{
    margin-bottom: 25px;
}
.popup-content .list li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 18px;
    color: #393337;
}
.popup-content .list li span{
    width: 50%;
}
.payment-info{
    text-align: center;
}
.payment-info .popup-image{
    margin-top: 50px;
}
.payment-info p{
    margin-bottom: 30px;
}
.payment-info h5{
    text-align: center;
    margin-bottom: 10px;
    color: #393337;
}
.payment-info .buttons{
    display: flex;
    justify-content: center;
}
.payment-info .primary{
    background: #393337;
    border-radius: 4px;
    padding: 2px;
    width: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--gray-color);
}
.fix{
    position: absolute;
    height: 20px;
    width: 30px;
    top: 0px;
    right: 0;
    border-top-right-radius: 15px;
    background-color: #898e99;
}
.fix:nth-of-type(2){
    bottom: 0;
    top: unset;
    border-top-right-radius: 0;
    border-bottom-right-radius: 15px;
}
@media (min-width: 1680px) and (max-width: 1920px){
    .popup-content {
        width: 700px;
    }
}
@media (min-width: 1921px){
    .popup-content {
        width: 850px;
    }
}
@media (max-width: 991px){
    .popup-content{
        max-width: calc(100% - 40px);
    }
    .popup-close{
        top: 20px;
    }
}
@media (max-width: 767px) {
    .popup-close{
        top: 17px;
    }
    .catalog {
        padding-bottom: 90px;
    }
    .tracks{
        padding-left: 10px;
        padding-right: 10px;
    }
    .popup-content{
        max-width: calc(100% - 20px);
        padding: 0 10px 0;
        height: calc(var(--window-inner-height) * 100 - 60px);
        max-height: calc(100vh - calc(100vh - 100%) - 60px);
        overflow: hidden;
    }
    .popup-content-scroll{
        padding: 0;
        /*max-height: 100%;*/
    }
    .popup-top-info{
        margin-bottom: 20px;
        margin-top: 17px;
    }
    .popup-content .popup-image{
        margin-bottom: 20px;
        width: 50%;
    }
    .popup-content-scroll::-webkit-scrollbar{
        width: 0;
        height: 0;
    }
    .tracks{
        padding-top: 82px;
    }
}