.single-track{
    width: calc(100%/3 - 20px/3);
    margin-right: 10px;
    margin-bottom: 30px;
}
.single-track:nth-child(3n){
    margin-right: 0;
}
.single-track-image{
    position: relative;
    margin-bottom: 10px;
}
.single-track-image img{
    width: 100%;
}
.single-track-image button{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.single-track-image:hover button{
    opacity: 1;
    visibility: visible;
}
.btn-listen{
    border: 1px solid var(--orange-color);
    background: #010102;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: var(--orange-color);
    padding: 0 6px;
    text-transform: uppercase;
    transition: all 0.4s ease;
}
.single-track h5{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--gray-color);
}
.single-track p{
    font-size: 16px;
    line-height: 18px;
    color: var(--gray-color);
}
.single-track-buttons{
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.single-track-buttons .btn-buy{
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #1e1e1e;
    padding: 1px 4.5px;
    text-transform: uppercase;
    background: var(--gray-color);
    border-radius: 4px;
    transition: all 0.4s ease;
    border: 1px solid var(--gray-color);
}
.single-track-buttons .btn-info{
    border: 1px solid var(--gray-color);
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    padding: 1px 5.5px;
    text-transform: uppercase;
    color: var(--gray-color);
    transition: all 0.4s ease;
}
.checkout-form{
    display: flex;
}
.single-track:nth-child(3n - 2) h5, .single-track:nth-child(3n - 2) p, .single-track:nth-child(3n - 2) .btn-buy{
    margin-left: 10px;
}
.single-track-buttons .btn-info:hover{
    background: #898E99;
    color: #1E1E1E;
}
.single-track-buttons .btn-buy:hover{
    background-color: transparent;
    color: var(--gray-color);
}
@media (max-width: 991px){
    .single-track{
        width: calc(50% - 5px);
        margin-right: 10px;
    }
    .single-track:nth-child(3n){
        margin-right: 10px;
    }
    .single-track:nth-child(2n){
        margin-right: 0;
    }
}
@media (max-width: 767px){
    .single-track-image button{
        opacity: 1;
        visibility: visible;
    }
    .single-track{
        width: 100%;
        margin-right: 0;
        margin-bottom: 50px;
    }
    .single-track:last-child{
        margin-bottom: 0;
    }
    .single-track:nth-child(3n){
        margin-right: 0;
    }
    .single-track:nth-child(3n - 2) h5, .single-track:nth-child(3n - 2) p, .single-track:nth-child(3n - 2) .btn-buy{
        margin-left: 0;
    }
    .single-track-image img{
        margin-left: -10px;
        width: calc(100% + 20px);
    }
}