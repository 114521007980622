.publishing{
    background: #1E1E1E;
    padding-bottom: 110px;
}
.publishing +.footer{
    background: #1E1E1E;
}
.publishing-top{
    position: relative;
    height: 100vh;
    margin: 0 -10px;
}
.publishing-top img{
    width: 100%;
    max-height: 750px;
    object-fit: cover;
}
.publishing-top video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
}
.publishing-top .h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.publishing-text{
    display: flex;
    justify-content: space-between;
    /*padding: 0 10px;*/
    margin-bottom: 30px;
    margin-top: 30px;
}
.publishing-text .left-side{
    max-width: 500px;
}
.publishing-text .right-side{
    width: calc(100% - 570px);
}
.publishing-text p{
    color: var(--gray-color);
    margin-bottom: 30px;
}
.publishing-text ul{
    margin-bottom: 30px;
}
.publishing-text p span{
    font-weight: bold;
}
.publishing-text  .h5{
    color: var(--gray-color);
}
.publishing-text .right-side .h5{
    margin-bottom: 10px;
}
.publishing-text li{
    color: var(--gray-color);
    position: relative;
    padding-left: 20px;
}
.publishing-text li:before{
    content: "";
    position: absolute;
    left: 5px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--gray-color);
}
.iframe-container{
    padding:56.25% 0 0 0;
    position:relative;
    background: #22222E;
    height: 100%;
}
.iframe{
    position:absolute;top:0;left:0;width:100%;height:100%;
}
.iframe iframe{
    position:absolute;top:0;left:0;width:100%;height:100%;
}
.sync{
    padding: 70px 0;
    text-align: center;
    color: var(--gray-color);
}
.sync p{
    margin: 10px 0 30px;
    color: var(--gray-color);
}
.sync a{
    font-weight: 700;
    font-size: 65px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #D3D3D3;
}
p.music-title{
    padding: 0 10px 10px;
    color: var(--gray-color);
}
.spotify-block{
    margin: 0 -10px;
}
.full-catalog{
    text-align: right;
    color: var(--gray-color);
    padding: 10px 10px 0;
    margin-bottom: 23px;
}
.full-catalog a{
    color: #ff4200;
}
@media (max-width: 991px){
    .publishing-text{
        width: 100%;
        margin: 30px 0;
        flex-direction: column;
    }
    .publishing-text p, .publishing-text li, .sync p{
        font-size: 16px;
        line-height: 18px;
    }
    .publishing-text p{
        margin-bottom: 20px;
    }
    .sync{
        padding: 60px 0;
    }
    .sync a{
        font-size: 32px;
        line-height: 32px;
    }
    .publishing-text .right-side{
        width: 100%;
        margin-top: 0;
    }
    .publishing-text .right-side .h5{
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px){
    .sync{
        padding: 30px 0;
    }
    .publishing{
        padding-bottom: 70px;
    }
    .publishing-text{
        padding: 0;
    }
    .full-catalog{
        margin-bottom: 18px;
    }
}