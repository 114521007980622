.info{
    background: #010102;
}
.info-wrapper{
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    overflow: hidden;
    padding: 0 10px;
}
.info-wrapper .left-side{
    position: relative;
    display: flex;
    align-items: center;
}
.info-wrapper .left-side img{
    position: absolute;
    width: 314px;
    left: 120px;
    object-fit: contain;
    top: 120px;
}
.info-wrapper .left-side .h2{
    margin-left: 120px;
    margin-bottom: 200px;
    position: relative;
}
.info-wrapper .right-side{
    width: 790px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 200px;
    position: relative;
}
.info-wrapper .right-side p{
    font-size: 20px;
    line-height: 24px;
    color: var(--gray-color);
    margin-bottom: 24px;
}
.info-wrapper .right-side p:last-child{
    margin-bottom: 0;
}
@media (max-width: 1350px){
    .info-wrapper{
        flex-direction: column;
        justify-content: flex-start;
    }
    .info-wrapper .left-side .h2{
        margin-left: 0;
        margin-top: 100px;
        margin-bottom: 0;
    }
    .info-wrapper .left-side img{
        width: 300px;
        opacity: 0.5;
    }
    .info-wrapper .right-side{
        width: 100%;
        padding-top: 30px;
    }
    .info-wrapper .right-side p{
        font-size: 14px;
        line-height: 17px;
    }
}