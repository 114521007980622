:root {
    color-scheme: light only;
}
body{
    margin: 0;
    padding: 0;
    font-family: 'Coil', sans-serif;
    -webkit-font-smoothing: antialiased;
    cursor: url('assets/images/curcor.svg') 16 16, auto;
}
body::-webkit-scrollbar{
    width: 0;
    height: 0;
}
*:focus {
    outline: none;
}
a{
    text-decoration: none;
    color: inherit;
}
button, button:hover, a, a:hover{
    cursor: url('assets/images/curcor.svg') 16 16, auto;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}
img, picture{
    display: block;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
button{
    border:none;
    background-color: transparent;
    font-family: inherit;
    padding: 0;
    margin: 0;
}
* {
    box-sizing: border-box;
}

/* StyleGuide */
:root {
    --gray-color: #898E99;
    --orange-color: #ff4200;
    --dark-color: #383337;
}
.orange{
    color: var(--orange-color);
}
.dark{
    color: var(--dark-color);
}
.gray{
    color: var(--gray-color);
}
.h2{
    font-weight: bold;
    font-size: 65px;
    line-height: 65px;
    letter-spacing: -0.04em;
}
.h4{
    font-weight: 700;
    font-size: 35px;
    line-height: 36px;
    letter-spacing: -0.01em;
}
.h5, .privacy-content h3{
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
}
.h6{
    font-size: 16px;
    line-height: 18px;
    color: #393337;
    font-weight: bold;
}
p.large, li.large, .about-text p, .about-content p{
    font-weight: 400;
    font-size: 30px;
    line-height: 31px;
    letter-spacing: -0.01em;
}
.p, .single-project-text-content h3, .single-project-text-content p, .single-project-text-content li, .privacy-content p, .publishing-text p, .publishing-text li{
    font-size: 22px;
    line-height: 23px;
}
.single-project-text-content ul{
    padding-top: 10px;
    padding-bottom: 10px;
}
.single-project-text-content ul li{
    padding-left: 20px;
    position: relative;
    padding-bottom: 10px;
}
.single-project-text-content ul li:before{
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--gray-color);
}

@media (min-width: 2560px){
    p.large, li.large, .h4, .about-text p, .about-content p{
        font-size: 52px;
        line-height: 54px;
    }
    .p, .h5, .single-project-text-content h3, .single-project-text-content p, .single-project-text-content li, .privacy-content p, .publishing-text p, .publishing-text li, .privacy-content h3{
        font-size: 33px;
        line-height: 34px;
    }
}
@media (min-width: 1920px) and (max-width: 2559px){
    p.large, li.large, .h4, .about-text p, .about-content p{
        font-size: 43px;
        line-height: 45px;
    }
    .p, .h5, .single-project-text-content h3, .single-project-text-content p, .single-project-text-content li, .privacy-content p, .publishing-text p, .publishing-text li, .privacy-content h3{
        font-size: 27px;
        line-height: 28px;
    }
}
@media (max-width: 1024px){
    p.large, li.large, .h4, .about-text p, .about-content p, .publishing-text p, .publishing-text li{
        font-size: 28px;
        line-height: 29px;
    }
    .h2{
        font-size: 52px;
        line-height: 52px;
    }
}
@media (max-width: 450px){
    p.large, li.large, .h4, .about-text p, .about-content p{
        font-size: 24px;
        line-height: 25px;
    }
    .p, .h5, .single-project-text-content h3, .single-project-text-content p, .single-project-text-content li, .privacy-content p, .publishing-text p, .publishing-text li, .privacy-content h3{
        font-size: 16px;
        line-height: 18px;
    }
    .h2{
        font-size: 40px;
        line-height: 40px;
    }
}

.video-buttons{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}
.video-buttons .small-btn{
    border: 1px solid #898E99;
    border-radius: 4px;
    padding: 1px 5.5px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #898E99;
}
/* Ochre */
.ochre-transport-wrapper.ochre-player-footer{
    height: 31px;
    padding: 0 10px;
}
.orange-player .ochre-transport-wrapper.ochre-player-footer{
    background: #898E99;
    transition: all 0.4s ease;
}
.orange-player .ochre-transport-wrapper.ochre-player-footer .ochre-playlist-wrapper{
    background-color: var(--orange-color);
    border-bottom: 1px solid #393337;
}
.ochre-playlist-item:has(.WarpIcons[style="display: inline;"]) .ochre-track-title.track-play-link {
    font-weight: bold;
}
.ochre-player{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Coil', sans-serif;
}
.ochre-player-header{
    font-family: 'Coil', sans-serif;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.ochre-player-header .h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}
.ochre-player-header .h5:first-child{
    width: calc(52.2% + 5px);
}
.ochre-player-header .h5:nth-child(2){
    width: 20%;
}
.ochre-player-header .h5:nth-child(3){
    width: 12%;
    margin-right: auto;
}
.ochre-player-header .h5:nth-child(4){
    width: 10%;
    text-align: right;
}
.ochre-currently-playing{
    order: 2;
    margin-right: auto;
    margin-left: 61px;
    display: flex;
    align-items: center;
    width: auto !important;
    max-width: calc(100% - 450px);
}
.ochre-player-footer .ochre-currently-playing a.ochre-track-title, .ochre-player-footer .ochre-currently-playing a.ochre-track-artist{
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
    text-decoration: none;
    pointer-events: none;
}
.ochre-minimize-toggle.ochre-js-toggle-minimized{
    margin-right: 0;
}
.orange-player .ochre-player-footer .ochre-currently-playing a.ochre-track-title, .orange-player .ochre-player-footer .ochre-currently-playing a.ochre-track-artist, .orange-player .ochre-player-footer .ochre-next-button.ochre-js-next:before, .orange-player .ochre-player-footer .ochre-prev-button.ochre-js-prev:before, .orange-player .ochre-player-footer .ochre-pause-button.ochre-js-pause i, .orange-player .ochre-player-footer .ochre-play-button.ochre-js-play i, .orange-player .ochre-player-footer .ochre-minimize-toggle:before, body .ochre-cart-toggle.orange-bg .ochre-cart-count, body .ochre-cart-toggle.orange-bg .ochre-cart-count:before{
    color: #262626;
    transition: all 0.4s ease;
}
.ochre-playlist .ochre-track-artist, .ochre-playlist .ochre-track-release{
    pointer-events: none;
}
.ochre-player-footer .ochre-player-controls{
    width: auto !important;
    display: flex;
    align-items: center;
    order: 1;
    padding-top: 0 !important;
}
.ochre-other-controls{
    order: 3;
    padding-top: 0 !important;
}
.ochre-transport-wrapper.ochre-player-footer{
    background-color: #000;
    z-index: 1;
}
.ochre-track-artwork-link{
    display: none;
}
.ochre-prev-button.ochre-js-prev{
    position: relative;
    text-decoration: none !important;
}
.ochre-prev-button.ochre-js-prev.ochre-disabled, .ochre-prev-button.ochre-js-next.ochre-disabled{
    opacity: 1;
}
.ochre-prev-button.ochre-js-prev:before{
    content: 'PREVIOUS';
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
    font-family: 'Coil', sans-serif;
    letter-spacing: -0.02em;
}
.ochre-prev-button.ochre-js-prev > *{
    display: none;
}
.ochre-next-button.ochre-js-next{
    position: relative;
    text-decoration: none !important;
}
.ochre-next-button.ochre-js-next:before{
    content: 'NEXT';
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
    font-family: 'Coil', sans-serif;
}
.ochre-next-button.ochre-js-next > *{
    display: none;
}
body .ochre-player-controls a{
    margin-right: 17px;
}
.ochre-play-button.ochre-js-play, .ochre-pause-button.ochre-js-pause{
    text-decoration: none !important;
    margin-left: -3px;
    margin-right: 14px;
}
.ochre-play-button.ochre-js-play i, .ochre-pause-button.ochre-js-pause i{
    color: var(--gray-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 30px;
}
.ochre-progress-controls, .ochre-mute.ochre-js-mute{
    display: none;
}
.ochre-minimize-toggle{
    position: relative;
    text-decoration: none !important;
}
.ochre-minimize-toggle > *{
    display: none;
}
.ochre-minimize-toggle:before{
    content: 'CLOSE';
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
    font-family: 'Coil', sans-serif;
    letter-spacing: -0.02em;
}
.ochre-playlist-toggle.ochre-js-toggle-playlist{
    position: absolute;
    text-decoration: none !important;
    left: 251px;
    margin-top: -2px;
}
body .ochre-currently-playing .ochre-track-title{
    margin-right: 17px;
}
.ochre-playlist-toggle.ochre-js-toggle-playlist.ochre-disabled{
    pointer-events: none;
}
.ochre-playlist-toggle.ochre-js-toggle-playlist.ochre-disabled:before{
    /*color: rgba(137, 142, 153, 0.5);*/
}
.ochre-playlist-toggle.ochre-js-toggle-playlist > *{
    display: none;
}
.ochre-playlist-toggle.ochre-js-toggle-playlist:before{
    content: "\2022 \2022 \2022";
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: var(--orange-color);
    font-family: 'Coil', sans-serif;
}
.fix-scroll, .fix-scroll body{
    overflow: hidden;
    position: fixed;
    box-sizing: border-box;
}
body .ochre-playlist-wrapper {
    bottom: 31px !important;
    background-color: #898E99;
    border-bottom: 1px solid #D3D3D3;
}
body .ochre-playlist-wrapper::-webkit-scrollbar{
    width: 0;
    height: 0;
}
body .ochre-currently-playing{
    padding: 4px 0;
}
.ochre-playlist-wrapper .ochre-playlist-item a, .ochre-track-duration, .ochre-track-number{
    font-family: "Coil", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #393337 !important;
    text-decoration: none !important;
}
.orange-player .ochre-transport-wrapper.ochre-player-footer .ochre-playlist-wrapper .ochre-playlist-item a, .orange-player .ochre-transport-wrapper.ochre-player-footer .ochre-track-duration, .orange-player .ochre-transport-wrapper.ochre-player-footer .ochre-track-number{
    color: #262626 !important;
}
body .ochre-playlist-item .ochre-track-number{
    width: 2.2%;
    margin: 0 5px 0 0;
}
body .ochre-track-duration{
    margin-right: 0;
}
.ochre-track-number .WarpIcons{
    display: none !important;
}
.ochre-track-number-text{
    display: block !important;
}
.ochre-playlist-wrapper #ochre-playlist{
    padding: 10px 10px 0;
}
.ochre-playlist-wrapper .ochre-playlist-item{
    border-bottom: none;
    padding: 0 0 10px;
}
body.simple-player{
    padding-bottom: 31px;
}
.btn-play{
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 3;
    margin: 0;
    transition: color 0.4s ease, background-color 0.4s ease, transform 0.4s ease;
}
#play-button{
    padding-left: 5px;
    padding-right: 5px;
}

body .ochre-product-list-container, body .ochre-cart-container{
    position: fixed;
    top: 10px;
    right: 78px;
    z-index: 2;
    background: #fff;
    font-family: 'Coil', sans-serif !important;
}
body .ochre-cart-container.hide{
    display: none;
}
body .ochre-cart-container{
    background-color: transparent;
}
body .ochre-cart-toggle{
    border: 1px solid var(--orange-color);
    padding: 3px 6px;
    transition: all 0.4s ease;
    background-color: transparent;
    display: flex;
    font-size: 0;
    position: relative;
}
body .ochre-cart-toggle:before{
    content: "Cart";
    font-weight: bold;
    font-size: 22px;
    line-height: 23px;
    color: var(--orange-color);
    transition: all 0.4s ease;
    text-transform: uppercase;
}
body .ochre-cart-toggle .ochre-cart-count{
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    background-color: transparent;
    color: var(--orange-color);
    margin-left: 0;
    padding: 0;
    transition: color 0.4s ease;
}
body .ochre-cart-toggle .ochre-cart-count:before{
    content: ":";
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    background-color: transparent;
    color: var(--orange-color);
    transition: color 0.4s ease;
}
body .ochre-cart-toggle.orange-bg:before{
    color: #383337;
}
body .ochre-cart-toggle.orange-bg{
    border: 1px solid #383337;
}
body .ochre-cart{
    right: -68px;
    left: unset;
    top: 20px;
    background: #393337;
    border: 1px solid #FF4200;
    padding: 10px 10px 0;
    overflow: auto;
    box-shadow: none;
    border-radius: 0;
    width: 280px;
}
body .ochre-cart::-webkit-scrollbar{
    width: 0;
    height: 0;
}
.ochre-cart-container.shown .ochre-cart-toggle{
    width: 212px;
    display: flex;
    justify-content: flex-end;
}
.ochre-cart-container.shown .ochre-cart{
    display: flex !important;
    flex-direction: column;
    max-height: calc(100vh - 80px);
}
.ochre-cart-container.shown .ochre-cart::-webkit-scrollbar{
    width: 0;
}
body .ochre-cart .ochre-cart-title, body .ochre-cart .ochre-cart-format, body .ochre-cart .ochre-cart-price, body .ochre-cart .ochre-cart-artist, body .ochre-cart .ochre-cart-variant{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FF4200;
}
body .ochre-cart-delete-button{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #898E99;
}
body .ochre-cart-table thead{
    display: none;
}
body .ochre-cart-table tr{
    display: flex;
}
body .ochre-cart-table tr.ochre-cart-item{
    flex-direction: column;
    border-bottom: 1px solid #ff4200;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
body .ochre-cart-table tr td{
    border: none;
    padding: 0;
}
body .ochre-cart-table tr:first-child td{
    border-top: none;
    /*white-space: nowrap;*/
}
body .ochre-cart-table tr .ochre-cart-price{
    text-align: left;
}
body .ochre-cart-total-wrapper{
    display: flex !important;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FF4200;
    padding-bottom: 30px;
}
body .ochre-cart-total-wrapper .ochre-cart-total{
    font-size: 16px;
    line-height: 18px;
}
body .ochre-cart-store-name{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #393337;
    display: none !important;
}
body .ochre-checkout-button{
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    text-transform: uppercase;
    color: #FF4200;
    border: none;
    text-align: left;
    position: relative;
    padding: 4px 0;
    margin: 0;
}
body .ochre-checkout-button:before{
    content: "";
    width: calc(100% + 20px);
    left: -10px;
    height: 1px;
    background-color: #ff4200;
    position: absolute;

    top: 0;
}
.ochre-transport-wrapper .animate-flicker{
    display: none !important;
}

@media (max-width: 800px) {
    body .ochre-player-footer .ochre-currently-playing {
        height: unset;
        position: static;
        bottom: unset;
        font-size: inherit;
        left: unset;
        max-width: calc(100% - 450px);
    }
    body .ochre-player-footer .ochre-player-controls{
        position: static;
    }
    body .ochre-player-footer .ochre-minimize-toggle{
        display: block;
    }
}
@media (max-width: 767px){
    body.simple-player{
        padding-bottom: 28px;
    }
    .btn-play{
        top: 5px;
    }
    #play-button, #stop-button{
        /*padding: 1px 3px;*/
    }
    body .ochre-cart{
        padding: 10px;
        right: 6px;
        top: 18px;
    }
    body .ochre-cart-toggle{
        margin-right: 74px;
        line-height: 18px;
        padding: 2px 4px;
    }
    body .ochre-cart-toggle .ochre-cart-count{
        margin-left: 0;
    }
    body .ochre-cart-container{
        right: 4px;
        top: 5px;
    }
    body .ochre-player-footer .ochre-minimize-toggle:before{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-player-footer .ochre-minimize-toggle{
        display: inline-block;
        margin-right: 10px;
    }
    .ochre-next-button.ochre-js-next:before, .ochre-prev-button.ochre-js-prev:before{
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
    }
    .ochre-play-button.ochre-js-play i, .ochre-pause-button.ochre-js-pause i{
        font-size: 20px;
    }
    .ochre-play-button.ochre-js-play, .ochre-pause-button.ochre-js-pause{
        margin-right: 7px;
    }
    .ochre-player-footer .ochre-player-controls{
        position: relative !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    .ochre-player-footer .ochre-currently-playing{
        display: none;
    }
    .ochre-playlist-toggle.ochre-js-toggle-playlist:before{
        font-size: 20px;
    }
    .ochre-transport-wrapper.ochre-player-footer {
        height: 28px;
        padding: 5px 10px;
    }
    .ochre-cart-container.shown .ochre-cart-toggle {
        width: 212px;
    }
    body .ochre-player-controls a{
        margin-right: 10px;
    }
    .ochre-player-footer .ochre-other-controls{
        top: 1px !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    .ochre-track-duration{
        width: 15%;
        min-width: 50px;
    }
    .ochre-playlist-item .ochre-track-title{
        width: 65% !important;
    }
    .ochre-playlist-wrapper .ochre-playlist-item{
        padding-bottom: 5px;
    }
    body .ochre-playlist-wrapper{
        bottom: 28px !important;
    }
    .ochre-playlist-toggle.ochre-js-toggle-playlist{
        position: relative;
        left: calc(-100vw + 279px);
        margin-top: 3px;
        transition: none;
    }
    .ochre-playlist-wrapper .ochre-playlist-item a, .ochre-track-duration, .ochre-track-number{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-playlist-item .ochre-track-number{
        width: 5%;
        min-width: 22px
    }
    .ochre-playlist-wrapper #ochre-playlist{
        padding-top: 5px;
    }
    body .ochre-cart-toggle .ochre-cart-count:before{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-buy-button:before{
        padding-top: 1px;
        padding-bottom: 1px;
    }
}
@media (max-width: 800px){
    .ochre-player-header .h5:nth-child(2){
        display: none;
    }
    .ochre-player-header .h5:nth-child(3){
        display: none;
    }
}
@media (max-width: 450px){
    body .ochre-cart-toggle:before, body .ochre-cart-toggle .ochre-cart-count{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-cart{
        padding: 10px;
        right: 10px;
        top: 13px;
    }
    .ochre-cart-container.shown .ochre-cart-toggle {
        /*width: 227px;*/
        width: 68px;
    }
    body .ochre-cart-toggle{
        padding: 2px 6px;
        right: 63px;
        margin-right: 0;
    }
    body .ochre-cart-total-wrapper .ochre-cart-total{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-cart-container{
        right: 0;
    }
}
@media (min-width: 2560px) {
    body .ochre-cart-toggle:before, body .ochre-cart-toggle .ochre-cart-count:before, body .ochre-cart-toggle .ochre-cart-count{
        font-size: 33px;
        line-height: 34px;
    }
    body .ochre-product-list-container, body .ochre-cart-container{
        right: 106px;
    }
    #play-button{
        padding-left: 4px;
        padding-right: 4px;
    }
    body .ochre-cart{
        top: 31px;
        right: -96px;
        width: 308px;
    }
}
@media (min-width: 1920px) and (max-width: 2559px){
    body .ochre-cart-toggle:before, body .ochre-cart-toggle .ochre-cart-count:before, body .ochre-cart-toggle .ochre-cart-count{
        font-size: 27px;
        line-height: 28px;
    }
    body .ochre-product-list-container, body .ochre-cart-container{
        right: 91px;
    }
    body .ochre-cart{
        top: 25px;
        right: -81px;
        width: 293px;
    }
}