.single-release-section{
    background-color: #393337;
    padding-bottom: 50px;
    min-height: 100vh;
}
.shop-item-wrapper{
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    width: calc(100% - 20px);
    margin: 0 auto 40px;
}
.shop-item-wrapper .left-side{
    margin-right: 10px;
    width: 40%;
}
.shop-item-wrapper .left-side > img{
    width: 100%;
}
.shop-item-wrapper .left-side .images{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
}
.shop-item-wrapper .left-side .images img{
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: contain;
    object-position: top;
}
.shop-item-wrapper .left-side .images img:nth-child(2n){
    margin-right: 0;
}
.shop-item-wrapper .right-side{
    color: var(--gray-color);
    width: 90%;
}
.shop-item-wrapper .right-side .text{
    color: var(--gray-color);
    font-size: 16px;
    line-height: 18px;
}
.shop-item-wrapper .right-side .divider{
    background-color: var(--gray-color);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.shop-item-wrapper .right-side .producer{
    margin-bottom: 20px;
    display: flex;
    margin-top: 20px;
}
.shop-item-wrapper .right-side .content{
    white-space: pre-line;
}
.shop-item-wrapper .right-side .btn-read{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--gray-color);
    padding: 2px 6px;
    border: 1px solid var(--gray-color);
    border-radius: 4px;
    display: block;
    margin-top: 20px;
    transition: all 0.4s ease;
}
.shop-item-wrapper .right-side .btn-read:hover{
    background: #898E99;
    color: #393337;
}
.shop-item-wrapper .right-side .product-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.shop-item-wrapper .right-side .currency{
    text-transform: uppercase;
}
.shop-item-wrapper .right-side .formats{
    display: flex;
    margin-top: 10px;
}
.shop-item-wrapper .right-side .btn-format-selected{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--gray-color);
    padding: 2px 6px 2px 20px;
    border: 1px solid var(--gray-color);
    border-radius: 4px;
    display: block;
    position: relative;
    margin-right: 10px;
}
.shop-item-wrapper .right-side .btn-format{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #393337;
    padding: 2px 6px 2px 20px;
    border: 1px solid #393337;
    border-radius: 4px;
    display: block;
    position: relative;
    margin-right: 10px;
}
.shop-item-wrapper .right-side .btn-format-selected:before{
    content: "";
    position: absolute;
    background: var(--gray-color);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 7px;
    left: 6px;
}
.shop-item-wrapper .right-side .btn-format:before{
    content: "";
    position: absolute;
    background: #393337;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 7px;
    left: 6px;
}
.release-tracks {
    width: calc(90% - 20px);
    margin: 0 10px 70px;
}
.release-tracks .p{
    color: var(--gray-color);
    margin-bottom: 10px;
}
.release-tracks .release-single{
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
}
.release-tracks .title-track:hover{
    color: #ff4200;
}
.release-tracks .title-track{
    transition: all 0.4s ease;
}
.release-tracks .release-single:after{
    content: "";
    width: 100%;
    height: 1px;
    background: var(--gray-color);
    position: absolute;
    bottom: 0;
    left: 0;
}
.release-tracks .title-track {
    margin-right: auto;
}
.release-tracks .title-track, .release-tracks .time-track {
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
}
.release-single-right{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
div[class^='release-single-cart-']{
    width: 100%;
    /*background-color: #010102;*/
    z-index: 2;

}
div[class^='release-single-cart-'] li:first-child{
    padding-top: 10px !important;
}
div[class^='release-single-cart-'] li{
    display: flex;
 }
div[class^='release-single-cart-'] li:last-child{
    padding-bottom: 0;
}
div[class^='release-single-cart-'] li:last-child:after{
    display: none;
}
 .product-list{
     margin-bottom: 20px;
     /*border-top: 1px solid var(--gray-color);*/
     margin-top: 20px;
 }
 .bundle-wrapper{

 }
.release-single-right .btn-buy{
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #393337;
    padding: 1px 6px;
    text-transform: uppercase;
    background: var(--gray-color);
    border-radius: 4px;
    transition: all 0.4s ease;
    box-sizing: border-box;
    border: 1px solid var(--gray-color);
}
.release-single-right .btn-buy:hover, .product-links .btn-external:hover{
    background-color: transparent;
    color: var(--gray-color);
}
.product-links{
    display: flex;
    margin-bottom: 20px;
}
.product-links .btn-external{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    background-color: var(--gray-color);
    color: #393337;
    padding: 1px 5px;
    text-transform: uppercase;
    margin-top: 0 !important;
    margin-right: 10px;
    border-radius: 4px;
    transition: all 0.4s ease;
    border: 1px solid var(--gray-color);
}
body .ochre-buy-button{
    font-size: 0;
}
body .ochre-buy-button:before{
    content: "Add";
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding: 2px 6px;
    background-color: transparent;
    border: 1px solid #898E99;
    border-radius: 4px;
    text-transform: uppercase;
    color: #898E99;
    transition: all 0.4s ease;
}
body .ochre-buy-button.added{
    pointer-events: none;
}
body .ochre-buy-button.added:before{
    content: "IN CART";
}
.btn-play-single{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent var(--gray-color);
    margin-right: 20px;
}
body .ochre-buy-button{
    border: 0;
    display: inline-flex;
    padding: 0;
}
body .ochre-product-price-wrapper{
    float: none;
    display: flex;
    align-items: center;
}
body .ochre-product-list li{
    position: relative;
    padding: 10px 0;
    border: 0;
    display: flex;
    align-items: center;
    gap: 20px;
}
body .ochre-product-list li:first-child{
    border-top: none;
    padding-top: 0;
}
body .ochre-product:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--gray-color);
    height: 1px;
    width: 100%;
}
.product-info-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.ochre-products-store-name{
    display: none!important;
}
body .ochre-product-format{
    font-weight: 400;
    font-size: 22px;
    line-height: 23px;
    color: var(--gray-color);
}
body .ochre-product-release-date, body .ochre-product-preorder-flag{
    font-size: 16px;
    line-height: 18px;
    display: flex;
    color: var(--gray-color);
}
body .ochre-product-price{
    font-style: normal;
    font-size: 22px;
    line-height: 23px;
    margin-right: 0;
    min-width: 110px;
    text-align: left;
    color: var(--gray-color);
}
body .ochre-product-format-wrapper{
    width: 105px;
    float: none;
}

.swiper {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

body {
    background: #000;
    color: #000;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.main-slider {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    z-index: 0 !important;
}

.thumb-slider {
    height: 70px;
    box-sizing: border-box;
    padding: 10px 0;
}

.thumb-slider .swiper-slide {
    height: 100%;
    opacity: 1;
    width: 70px;
}
.thumb-slider .swiper-slide img{
    width: 70px;
    height: 70px;
}

.thumb-slider .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-navigation{
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
.main-slider-wrapper{
    position: relative;
}
.total-slides{
    font-weight: 400;
    font-size: 22px;
    line-height: 23px;
    color: #898E99;
    width: fit-content;
    margin-bottom: 10px;
}
.cursor-prev {
    cursor: w-resize;
}

.cursor-next {
    cursor: e-resize;
}
.custom-navigation div{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--gray-color);
    background: #1E1E1E;
    border-radius: 4px;
    padding: 2px 6px;
    width: fit-content;
}
.modal-wrapper{
    position: fixed;
    z-index: 11;
    height: 100vh;
    background-color: #000;
    top: 0;
    left: 0;
    right: 0;
    display: none;
}
.modal-wrapper.active{
    display: block;
}
.modal-close{
    position: absolute;
    z-index: 2;
    top: 13px;
    left: 10px;
    font-weight: 700;
}
.modal-slider{
    width: 100%;
    height: 100%;
}
.modal-slider .swiper-slide{
    background-color: #000;
}
.modal-slider .swiper-slide img{
    object-fit: contain;
}
.modal-wrapper .total-slides{
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2;
    margin-bottom: 0;
}
body .ochre-buy-button:hover:before{
    background: #898E99;
    color: #393337;
}
@media (min-width: 2560px) {
    .total-slides, body .ochre-product-price, body .ochre-product-format{
        font-size: 33px;
        line-height: 34px;
    }
    body .ochre-product-format-wrapper{
        width: 130px;
    }
    body .ochre-product-price{
        min-width: 130px;
    }
    body .ochre-buy-button:before{
        font-size: 22px;
        line-height: 23px;
    }
}
@media (max-width: 991px){
    .shop-item-wrapper{
        flex-direction: column;
        margin-bottom: 0;
        padding: 70px 0 80px;
    }
    .shop-item-wrapper .left-side{
        width: 100%;
        margin-right: 0;
    }
    .main-slider{
        height: 100%;
    }
    .shop-item-wrapper .left-side > img{
        width: 100%;
    }
    .shop-item-wrapper .left-side .images{
        width: 100%;
    }
    .shop-item-wrapper .right-side{
        width: 100%;
    }
    .release-tracks{
        padding-right: 0;
        padding-left: 0;
    }
    .shop-item-wrapper .right-side .content{
        font-size: 22px;
        line-height: 23px;
    }
}
@media (max-width: 767px){
    .modal-wrapper .total-slides{
        font-size: 16px;
        line-height: 18px;
        top: 9px;
        right: 10px;
        left: unset;
        bottom: unset;
    }
    .modal-close{
        top: 9px;
    }
    .release-tracks .title-track{
        width: calc(100% - 120px);
    }
    .release-single-right .btn-buy{
        margin-left: 10px;
    }
    .shop-item-wrapper .right-side .product-info + .producer{
        margin-top: 30px;
    }
    .release-tracks{
        margin-bottom: 20px;
        width: calc(100% - 20px);
    }
    .shop-item-wrapper{
        padding-bottom: 40px;
    }
    .single-release-section{
        padding-bottom: 100px;
    }
    .shop-item-wrapper .right-side .btn-read{
        padding: 1px 4px;
    }
    body .ochre-product-price, body .ochre-product-format, .total-slides{
        font-size: 16px;
        line-height: 18px;
    }
    body .ochre-product-price{
        min-width: 90px;
    }
    body .ochre-product-price-wrapper{
        width: auto;
    }
    body .ochre-product-list li{
        justify-content: space-between;
    }
}
@media (max-width: 450px){
    .shop-item-wrapper .right-side .content, .release-tracks .title-track, .release-tracks .time-track{
        font-size: 16px;
        line-height: 18px;
    }
    .shop-item-wrapper .right-side .producer{
        margin-top: 0;
    }
}