.footer{
    padding: 20px 10px 30px;
    background: transparent;
    border-top: 1px solid #898E99;
}

.footer-menu{
    display: flex;
    /*margin-bottom: 30px;*/
    justify-content: space-between;
}
.footer-links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
}
.footer-menu + .p{
    color: #383337;
}
.footer-contact{
    width: 50%;
}
.footer-contact p{
    color: #898E99;
}
.footer .p.light{
    color: var(--gray-color);
}
.footer-bottom{
    display: flex;
}
.copyright{
    color: #696A6A;
    width: 360px;
}
.footer-made{
    display: flex;
}
.footer-made{
    color: #696A6A;
}
.footer-made a{
    font-weight: 700;
}
.footer-made p:first-child{
    margin-right: 30px;
}
.popup .close{
    font-weight: 700;
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 10px;
}
.popup-contact{
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #D3D3D3;
    text-align: center;
}
.popup-contact.mailchimp{
    left: 10px;
    transform: translateY(-50%);
}
.popup-contact h5{
    margin-bottom: 10px;
    margin-top: 30px;
}
.popup-contact h5:first-of-type{
    margin-top: 0;
}
.popup-contact p, .popup-contact a{
    font-size: 16px;
    line-height: 19px;
}
.popup .btn-red{
    top: 10px;
    right: 10px;
    position: absolute;
}
.popup-contact.mailchimp div{
    display: flex;
    flex-direction: column;
    width: 280px;
    margin: 0;
}
.popup-contact.mailchimp div input{
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    color: #ff4200;
    border: none;
    background-color: transparent;
    margin-bottom: 30px;
    font-family: 'Coil', sans-serif;
}
.popup-contact.mailchimp div input::placeholder{
    color: #ff4200;
    opacity: 1;
}
.popup-contact.mailchimp div button{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #898E99;
    border-radius: 4px;
    color: #898E99;
    text-transform: uppercase;
    padding: 1px 6px;
    width: fit-content;
    margin: 0;
    transition: all 0.4s ease;
}
.popup-contact.mailchimp div button:hover{
    background-color: #898E99;
    color: #262626;
}
.popup-contact.mailchimp div + p{
    order: 3;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    color: #D3D3D3 !important;
    margin-top: 20px;
}
.cookie-wrapper{
    color: #898E99 !important;
    font-size: 22px;
    margin: 0 !important;
    border-top: 1px solid #898E99;
    padding: 5px 10px !important;
    z-index: 2 !important;
}
.cookie-text{
    margin: 0 !important;
}
.cookie-button{
    color: #898E99 !important;
    font-size: 22px;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important
}
@media (max-width: 991px){
    .footer-menu{
        flex-direction: column;
    }
    .footer-contact{
        margin-bottom: 20px;
        width: 100%;
    }
    .footer-links{
        margin-bottom: 20px;
        width: 100%;
    }
    .footer-links:last-child{
        margin-bottom: 10px;
    }
}
@media (max-width: 767px){
    .popup .close{
        top: 7px;
    }
    .popup{
        overflow: hidden;
        height: 0;
    }
    .popup.opened{
        height: auto;
    }
    .footer{
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 5px;
    }

    .popup-contact{
        padding: 0;
    }
    .popup-contact.mailchimp div input{
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #FF4200;
        margin-bottom: 10px;
        text-align: left;
        padding-left: 0;
    }
    .popup-contact.mailchimp div input::placeholder{
        color: #FF4200;
        opacity: 1;
    }
    .footer-menu + .p{
        display: none;
    }
    .popup-contact h5:first-of-type{
        display: none;
    }
    .popup .btn-red{
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        border: none;
        padding: 0;
        text-transform: lowercase;
        left: 10px;
        right: unset;
    }
    .popup-contact.mailchimp{
        position: static;
        transform: unset;
        margin-top: 140px;
        margin-left: 10px;
        margin-right: 10px;
        width: fit-content;
    }
    .popup-contact.mailchimp div button{
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #898E99;
        border: 1px solid #898E99;
        border-radius: 4px;
        padding: 1px 6px;
        background-color: transparent;
        margin: 0;
    }
    .footer-bottom, .footer-made{
        flex-direction: column;
    }
    .cookie-wrapper{
        font-size: 16px !important;
        line-height: 18px;
    }
    .cookie-button{
        font-size: 16px;
        line-height: 18px;
    }
}
@media (min-width: 2560px) {
    .popup-contact.mailchimp div input{
        font-size: 33px;
        line-height: 34px;
    }
    .popup-contact.mailchimp div button{
        font-size: 33px;
        line-height: 34px;
    }
}
