.btn-red{
    border: 1px solid var(--orange-color);
    font-weight: bold;
    color: var(--orange-color);
    padding: 3px 6px;
    text-transform: uppercase;
    transition: all 0.4s ease;
}
.btn-black{
    border: 1px solid #383337;
    font-weight: bold;
    color: #383337;
    padding: 3px 6px;
    text-transform: uppercase;
    transition: all 0.4s ease;
}
.btn-medium{
    border: 1px solid var(--gray-color);
    font-weight: bold;
    color: var(--gray-color);
    padding: 3px 6px;
    text-transform: uppercase;
    transition: all 0.4s ease;
}

@media (max-width: 767px){
    .btn-red, .btn-black, .btn-medium{
        font-size: 17px;
        line-height: 20px;
        padding: 2px 6px;
    }
}