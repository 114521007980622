.header{
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
}
.scroll-up .header, .scroll-up .btn-play, .scroll-up .ochre-cart-toggle, .scroll-up .header-artist-close, .scroll-up .header-artist h5{
    transform: none;
}
.scroll-down .header{
    transform: translate3d(0, -100%, 0);
}
.scroll-down .btn-play, .scroll-down .ochre-cart-toggle, .scroll-down .header-artist-close, .scroll-down .header-artist h5 {
    transform: translate3d(0, -41px, 0);
}

.header.hide{
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.logo-img{
    font-weight: 700;
    color: var(--orange-color);
    margin-top: 3px;
    transition: all 0.4s ease;
}

.popup-menu{
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;
}
.popup-menu.opened{
    background: rgba(30, 30, 30, 0.3);
    backdrop-filter: blur(50px);
    visibility: visible;
    opacity: 1;
    overflow: auto;
}
.popup-menu.opened::-webkit-scrollbar{
    width: 0;
    height: 0;
}
.popup-menu ul{
    margin-top: 60px;
    padding-left: 10px;
}
.popup-menu ul li{

}
.popup-menu ul li a, .popup-menu ul li button{
    font-weight: bold;
    font-size: 100px;
    line-height: 95px;
    letter-spacing: -0.03em;
    color: var(--orange-color);
    transition: all 0.4s ease;
    position: relative;
    left: 0;
    display: flex;
}
.popup-menu ul li button.active{
    color: #D3D3D3;
}
.popup-menu ul li .submenu{
    margin-top: 0;
    position: absolute;
    display: none;
}
.popup-menu ul li button.active + .submenu{
    display: block;
    left: 0;
    margin-left: 615px;
    top: 75px;
    height: 100%;
}
.popup-menu ul li:hover > a, .popup-menu ul li:hover > button{
    color: #D3D3D3;
}
.popup-menu ul li{
    width: fit-content;
}
.popup-menu ul li .submenu li a{
    font-weight: 700;
    font-size: 55px;
    line-height: 54px;
    letter-spacing: -0.03em;
    color: #D3D3D3;
}
.popup-menu ul li .submenu li a:hover{
    color: var(--orange-color);
}

.hidden-logo{
    width: 122px;
}

.logo-link{
    position: relative;
    z-index: 11;
    width: 122px;
    text-align: left;
}

.logo-link.dark .logo-img{
    color: #393337;
}

.header .btn-red, .header .btn-black{
    margin: 0;
}
@media (max-width: 991px) {
    .popup-menu ul{
        margin-top: 50px;
    }
    .popup-menu ul li .submenu{
        position: relative;
    }
    .popup-menu ul li button.active + .submenu{
        display: block;
        left: unset;
        margin-left: 0;
        top: unset;
        margin-top: 16px;
        height: calc(100% + 32px);
        margin-bottom: 16px;
        padding-left: 3px;
        width: calc(100% - 10px);
    }

}
@media (max-width: 767px) {
    .header{
        align-items: center;
        padding: 5px 10px;
    }
    .logo-img{
        margin-right: 23px;
        max-width: 27px;
        margin-top: 0;
        line-height: 22px;
    }
    .hidden-logo, .logo-link{
        width: 50px;
    }
    .popup-menu ul li a, .popup-menu ul li button{
        font-size: 50px;
        line-height: 50px;
    }
    .popup-menu ul li .submenu li a{
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        letter-spacing: -0.01em;
    }
    .scroll-down .btn-play, .scroll-down .ochre-cart-toggle, .scroll-down .header-artist-close {
        transform: translate3d(0, -36px, 0);
    }
}
@media (min-width: 2560px) {
    .scroll-down .btn-play, .scroll-down .ochre-cart-toggle, .scroll-down .header-artist-close {
        transform: translate3d(0, -60px, 0);
    }
}