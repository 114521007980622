.privacy{
    color: #898e99;
    padding-top: 150px;
    padding-bottom: 50px;
}
.privacy-content{
    width: 50%;
}
.container{
    padding: 0 10px;
}
.privacy .container p{
    margin-bottom: 22px;
}
.privacy-content table{
    margin-bottom: 22px;
}
@media (max-width: 991px){
    .privacy{
        padding-top: 70px;
    }
    .privacy-content{
        width: 100%;
    }
}
@media (max-width: 450px){
    .privacy .container p{
        margin-bottom: 18px;
    }
    .privacy-content table{
        margin-bottom: 18px;
    }
}