.about{
    background: #1E1E1E;
}
.about-wrapper{
    padding: 110px 10px 0;
    min-height: calc(100vh - 143px);
}
.about-wrapper .about-text{
    width: 90%;
    margin-bottom: 30px;
}
.about-wrapper .about-text p{
    color: var(--gray-color);
    margin-bottom: 31px;
}
.about-wrapper .list-accordion{
    padding-bottom: 110px;
    width: 90%;
}

.list-accordion li{
    border-top: 1px solid var(--gray-color);
    /*border-bottom: 1px solid var(--gray-color);*/
    color: var(--gray-color);
    padding: 20px 0;
    margin-bottom: -1px;
}
.list-accordion li p{
    transition: all 0.4s ease;
}
.list-accordion li:hover > p{
    color: #ff4200;
}
.list-accordion li .about-content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    opacity: 0;
}
.list-accordion li .about-content p{
    margin-bottom: 36px;
}
.list-accordion li .about-content p:last-child{
    margin-bottom: 0;
}
.list-accordion li .about-content p.no-offset{
    margin-bottom: 0;
}
.list-accordion li.active {
    position: relative;
}
.list-accordion li.active > p{
    color: var(--orange-color);
    margin-bottom: 36px;
}
.list-accordion li.active .about-content{
    max-height: max-content;
    margin: 10px 0 0;
    opacity: 1;
}

@media (max-width: 991px){
    .about-wrapper{
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 70px;
    }
    .about-wrapper .left-side .h2{
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        top: 60px;
    }
    .about-wrapper .left-side img{
        width: 300px;
        opacity: 0.5;
    }
    .about-wrapper .right-side{
        width: 100%;
        /*padding-top: 60px;*/
    }
    .about-wrapper .right-side p{
        /*font-size: 16px;*/
        /*line-height: 18px;*/
    }
    .about-wrapper .right-side li .about-title{
        font-size: 30px;
        line-height: 30px;
        padding-left: 30px;
    }
    .about-wrapper .right-side li .about-title:before{
        width: 20px;
        top: 13px;
    }
    .about-wrapper .right-side li .about-title:after{
        height: 20px;
        top: 5px;
        left: 9px;
    }
    .about-wrapper .right-side ul{
        margin-top: 50px;
        margin-bottom: 40px;
    }
}
@media (max-width: 767px){
    .about-wrapper .list-accordion{
        width: 100%;
    }
    .list-accordion ul{
        padding-bottom: 93px;
        width: 100%;
    }
    .list-accordion li .about-content p, .list-accordion li.active > p{
        margin-bottom: 25px;
    }
    .list-accordion li{
        padding: 10px 0;
    }
    .about-wrapper{
        padding-top: 60px;
    }
    .about-wrapper .about-text{
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .about-wrapper .about-text p{
        margin-bottom: 23px;
    }
}