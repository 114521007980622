.header-artist{
    position: fixed;
    z-index: 2;
    top: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-artist-close{
    font-weight: 700;
    color: var(--orange-color);
    transition: all 0.4s ease;
}
.header-artist h5{
    transition: all 0.4s ease;
}
.header-artist div{
    width: 55px;
}
.single-artist-section{
    background: #010102;
    padding-bottom: 50px;
    min-height: 100vh;
}
.artist-top{
    position: relative;
}
.artist-top h5{
    display: none;
}
.artist-top img{
    width: 100%;
    opacity: 0.7;
    height: 100vh;
    object-fit: cover;
}
.artist-top .half-image{
    height: 100vh;
    min-width: 50%;
    margin: 0 auto;
    object-fit: contain;
}
.artist-top h2{
    font-size: 110px;
    line-height: 100px;
    letter-spacing: -0.03em;
    color: #D3D3D3;
    position: absolute;
    top: 90px;
    left: 10px;
}
.artist-text{
    width: 60%;
    margin: 30px auto 120px 10px;
}
.artist-text p{
    color: var(--gray-color);
}
.artist-tracks{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
}
.artist-single-track{
    width: calc(20% - 8px);
    margin-right: 10px;
    margin-bottom: 10px;
}
.artist-single-track:nth-child(5n){
    margin-right: 0;
}
.artist-single-track .h6, .artist-single-track .p{
    color: var(--gray-color);
    margin-left: 10px;
}
.artist-single-track .p{
    font-size: 16px;
    line-height: 18px;
}
.artist-single-track img{
    width: 100%;
    margin-top: 10px;
}
.artist-videos{
    margin-bottom: 120px;
}
.artist-videos .iframe-container{
    background: #010102;
    margin-bottom: 30px;
}
.artist-videos .iframe-container + .video-buttons{
    margin-top: -20px;
}
.artist-videos .iframe-container:last-child{
    margin-bottom: 0;
}
.single-artist-section .title{
    color: var(--gray-color);
    margin-bottom: 30px;
    margin-left: 10px;
}
.custom-loader{
    background: #393337;
    position: fixed;
    opacity: 1;
    visibility: visible;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 1s ease;
    z-index: 10;
}
.custom-loader p{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-weight: 700;
    font-size: 35px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(137, 142, 153, 1);
}
.custom-loader.hide{
    opacity: 0;
    visibility: hidden;
}
@media (max-width: 991px){
    .artist-text{
        width: 80%;
        margin: 20px 10px;
    }
    .artist-text p{
        font-size: 18px;
        line-height: 19px;
    }
    .artist-top .half-image{
        width: 100%;
        object-fit: cover;
    }
    .artist-top h2{
        font-size: 32px;
        line-height: 40px;
        top: 60px;
    }
    .artist-single-track{
        width: calc(100%/3 - 20px/3);
        margin-right: 10px;
    }
    .artist-single-track:nth-child(3n){
        margin-right: 0;
    }
    .artist-tracks{
        margin-bottom: 30px;
    }
    .single-artist-section{
        min-height: 100vh;
    }
}
@media (max-width: 767px){
    .header-artist h5{
        display: none;
    }
    .artist-text{
        width: calc(100% - 20px);
        margin: 20px 10px;
    }
    .artist-text p{
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.002rem;
    }
    .artist-single-track{
        width: 100%;
        margin-right: 0;
    }
    .header-artist{
        height: 31px;
        top: 5px;
    }
    .single-artist-section{
        padding-bottom: 150px;
    }
    .artist-top .half-image{
        max-width: 100%;
        object-fit: cover;
    }
    .artist-videos{
        margin-bottom: 30px;
    }
    .artist-top h5{
        display: block;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 40px;
        line-height: 40px;
        width: 80%;
    }
}
@media (max-width: 450px) {
    .header-artist {
        height: 26px;
    }
}