.home-wrapper{
    transition: all 3s ease;
    background-color: #393337;
}
.hero{
    height: 100vh;
}
.hero.active{
    height: auto;
}


.home-image-block, .home-button-block{
    margin-bottom: 70px;
}
.home-image-block.right{
    margin-left: auto;
}
.home-image-block.fullscreen{
    width: 100%;
}
.home-image-block.small{
    width: 60%;
}
.home-image-block.large{
    width: 90%;
}
.home-image-block .home-image-top{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.home-image-block.fullscreen .home-image-top, .home-image-block.left .home-image-top{
    padding-left: 10px;
}
.home-image-block .home-image-content img{
    width: 100%;
    border: 1px solid #D3D3D3;
}
.home-image-block .home-image-top p{
    margin-right: 90px;
}
.release-top {
    margin: 0 10px 60px;
    background: #393337;
    border: 1px solid #d3d3d3;
    border-radius: 18px;
    padding: 30px;
    display: block;
    transition: all 0.4s ease;
}
.release-top .h2, .release-top .p{
    transition: all 0.4s ease;
}
.release-top:hover {
    border: 1px solid #ff4200;
    background-color: #898E99;
}
.release-top:hover .h2, .release-top:hover .p{
    color: #000;
}
.loader{
    background: #393337;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: block;
    z-index: 4;
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
    padding-top: 10px;
}
.loader p{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    color: rgba(137, 142, 153, 1);
    opacity: 0;
    animation-name: fadeInOut;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}
.loader .logo{
    opacity: 0;
    animation-name: logoFadeIn;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-delay: 2s;
    width: 100%;
    padding: 0 8px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.loader.hide{
    background-color: transparent;
    position: static;
    height: auto;
}
.loader.hide p{
    display: none;
}
.loader.hide .logo{
    animation: none;
    margin-top: 0;
    opacity: 1;
    position: relative;
    top: unset;
    transform: unset;
    transition: all 0.8s cubic-bezier(0, 0, 0.09, 1.02);
}
.home-content{
    margin-top: 120px;
    opacity: 0;
    transition: all 1s ease;
}
.home-content.active{
    opacity: 1;
}
.loader .logo.animate{
    transform: translate3d(0, -800px, 0);
    transition: all 1.2s ease-in-out;
}
@media (max-width: 991px){
    .home-image-block{
        width: 80%;
        margin-bottom: 70px;
    }
    .home-content{
        margin-top: 70px;
    }
}
@media (max-width: 767px){
    .home-content{
        margin-top: 110px;
        margin-bottom: 110px;
    }
    .loader{
        padding-top: 41px;
    }
    .loader p{
        width: 100%;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
    }
    .loader .logo{
        padding: 0 10px;
        animation-duration: 1.5s;
        animation-name: logoFadeInMobile;
    }
    .logo{
        padding: 120px 10px 60px;
    }
    .home-image-block, .home-image-block.small, .home-image-block.large, .home-button-block{
        width: 100%;
        margin-bottom: 50px;
    }
    .home-image-block .home-image-top{
        padding: 0 10px;
        justify-content: space-between;
        align-items: flex-end;
    }
    .home-image-block .home-image-top .p{
        font-weight: 400;
        letter-spacing: -0.01em;
        margin-right: 10%;
    }
    .home-image-block .home-image-top .h5{
        text-align: right;
    }
    .release-top {
        padding: 10px;
        border-radius: 10px;
    }
    .release-top .p {
        letter-spacing: -.01em;
        margin-bottom: 5px;
    }
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    50%   { opacity: 1; }
    100%   { opacity: 0; }
}


@keyframes logoFadeIn {
    0% { opacity: 0; top: 50%; transform: translateY(-50%)}
    100%   { opacity: 1; top: 0; transform: translateY(10px)}
}

@keyframes logoFadeInMobile {
    0% { opacity: 0; top: 50%; transform: translateY(-50%)}
    100%   { opacity: 1; top: 0; transform: translateY(40px)}
}